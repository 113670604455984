/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-10-08 19:03:38
 * @LastEditors: Zhao 2869210303@qq.com
 * @LastEditTime: 2024-07-30 09:53:10
 * @FilePath: \ustb-campus\frontend\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEim
 */

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/IndexView.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/SearchView.vue"),
  },
  {
    path: "/center", // 用户个人中心
    name: "UserCenter",
    component: () => import("@/views/MySelf.vue"),
  },
  {
    path: "/blog/:blog_id",
    name: "BlogDetail",
    component: () => import("@/views/BlogDetail.vue"),
  },
  {
    path: "/user/:openid",
    name: "User",
    component: () => import("@/views/UserCenter.vue"),
  },
  {
    path: "/new",
    name: "NewPost",
    component: () => import("@/views/NewPost.vue"),
  },
  {
    path: "/edit",
    name: "edit",
    component: () => import("@/views/EditView.vue"),
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
  // {
  //   path: '*',
  //   redirect: '/404',
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 检查路由参数中是否有 campusGroup
  const campusGroupParam = to.query.campusGroup;

  // 检查 campusGroupParam 是否存在并且可以转换为大于0的整数
  if (campusGroupParam) {
    const campusGroupNumber = parseInt(campusGroupParam, 10);
    if (!isNaN(campusGroupNumber) && campusGroupNumber > 0) {
      // 将 campusGroup 保存到本地缓存
      localStorage.setItem("campusGroup", campusGroupNumber.toString());
    }
  }

  const campusGroup = localStorage.getItem("campusGroup") || false;
  // const unprotectedRoutes = ['/']; // 不需要拦截的路由
  const protectedRoutes = ["/center", "/new", "/home"]; // 需要拦截的路由

  // 检查即将进入的路由是否需要保护
  if (!campusGroup && protectedRoutes.includes(to.path)) {
    // 如果没有 campusGroup，则重定向到首页
    next("/");
  } else {
    // 如果有 campusGroup 或者路由不需要保护，则正常导航
    next();
  }
});

export default router;
