<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-10-10 11:23:34
 * @LastEditors: Zhao 2869210303@qq.com
 * @LastEditTime: 2024-08-11 15:03:15
 * @FilePath: \ustb-campus\frontend\src\components\TopNav.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="top-nav">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="text-primary navbar-brand" href="#">
          <span> <van-image round width="30px"
              :src="logo" /></span>
          <span id="webtitle">贝壳墙</span>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav colorblack">
            <li class="nav-item">
              <a class="nav-link" href="#">
                <router-link to="/">首页</router-link>
              </a>
            </li>
            <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                关于
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a target="_blank" href="https://blog.csdn.net/V123456789987654" class="dropdown-item">作者的博客</a>
                </li>
                <li>
                  <a target="_blank" href="https://github.com/U202142209" class="dropdown-item">GitHub</a>
                </li>
                <a target="_blank" href="http://blog.wobushidalao.top/" class="dropdown-item">我的个人网站</a>
              </ul>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" target="_blank" href="https://www.ustb.edu.cn/">
                友情链接
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: localStorage.getItem("school_logo") || "https://ts2.cn.mm.bing.net/th?id=OIP-C.l2CZTKrBMgPy_teMTUnDgAHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&dpr=1.9&pid=3.1&rm=2",
    }
  },
}
</script>
<style>
#webtitle {
  margin-left: 10px;
  vertical-align: text-bottom;
  line-height: 40px
}
</style>